// 限时购倒计时
<template>
  <div class="CountDown">
    <span v-if="done">
      {{ tip }} {{ day }}天  <span class="TimeBox">{{ hour }}</span>:<span  class="TimeBox">{{ min }}</span> :<span  class="TimeBox">{{ second }}</span>
      </span>
    <span v-else>{{ tip }}</span>
  </div>
</template>

<script>
// import moment from 'moment'
export default {
  name: "ProCountDown",
  props: {
    endTime: {
      required: true,
    },
    startTime: {
      required: true,
    }
  },
  data() {
    return {
      curStartTime: "",
      day: "0",
      hour: "00",
      min: "00",
      second: "00",
      done: false,
      tip: "",
    };
  },

  created() {
    this.curStartTime = this.endTime;
    this.countTime();
  },
  updated() {
    if (this.end || this.done) {
      clearInterval(this.timeInterval);
    }
  },
  methods: {
    // 倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // console.log('当前时间',now)
// 活动开始时间startTime -当前时间大于0，则 显示活动开始时间
let  startTime=new Date(this.startTime).getTime()-now;

if(startTime>0){
    //  console.log(this.startTime)
    //  活动开始倒计时
      this.done = true;
     this.tip = `距活动开始还剩：`;
      // 天
        this.day = Math.floor(startTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((startTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((startTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((startTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
        // 等于0的时候不调用
        if (
          Number(this.hour) === 0 &&
          Number(this.day) === 0 &&
          Number(this.min) === 0 &&
          Number(this.second) === 0
        ) {
          this.done = false;
          this.tip = `活动已开始`;
        } 
         // 递归每秒调用countTime方法，显示动态时间效果,
          setTimeout(this.countTime, 1000);
}else{
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;

      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime > 0) {
        this.done = true;
        this.tip = `距结束还剩 `;
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
      } else {
        this.done = false;
        this.tip = `活动已结束`;
        this.day = 0;
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        this.done = false;
        this.tip = `活动已结束`;
        clearInterval(this.countTime);
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
}

    },
  },
};
</script>

<style lang="less" scoped>
.CountDown {
  color: #FF4D00 ;
  font-size: 12px;
  text-align: center;
  .TimeBox{
    color: #000;
    background: #FF4D00 ;
    padding:2px;
    border-radius: 4px;
    font-size: 13px;
  }
}
button[disabled] {
  cursor: not-allowed;
}
</style>
